document.addEventListener('DOMContentLoaded', function () {
    const hamburgerIcon = document.getElementById('hamburger-icon');
    const navbar = document.getElementById('navbar');
    const body = document.querySelector('body');
    let isHamburgerInitialised = false;

    // Mobile menu display and animation
    function mobileMenu() {
        if (window.innerWidth <= 1279 && !isHamburgerInitialised) {
            hamburgerIcon.addEventListener('click', toggleMenu);
            isHamburgerInitialised = true;
        } else if (window.innerWidth > 1279 && isHamburgerInitialised) {
            hamburgerIcon.removeEventListener('click', toggleMenu);
            isHamburgerInitialised = false;
        }
    }
    function toggleMenu() {
        navbar.classList.toggle('open');
        hamburgerIcon.classList.toggle('open');
        body.classList.toggle('overflow-hidden');
    }

    // Submenu display and animation
    function mobileSubMenu() {
        if (window.innerWidth <= 1279) {
            const menuItems = document.querySelectorAll('.menu-item.has-children');
            menuItems.forEach(function (item) {
                item.addEventListener('click', function () {
                    const subMenu = item.querySelector('.submenu');
                    if (subMenu) {
                        if (subMenu.classList.contains('open')) {
                            subMenu.style.height = 0;
                            subMenu.classList.remove('open');
                            this.classList.remove('active');
                        } else {
                            subMenu.style.height = subMenu.scrollHeight + 'px';
                            subMenu.classList.add('open');
                            this.classList.add('active');
                        }
                    }
                });
            });
        }
    }

    mobileMenu();
    mobileSubMenu();

    window.addEventListener('resize', function () {
        mobileMenu();
        mobileSubMenu();
    });
});
