document.addEventListener("DOMContentLoaded", function () {
    initializeCookieConsent();
});

function initializeCookieConsent() {
    window.cookieConsent = document.cookie.split(';').some(item => item.includes('COOKIE_CONSENT=true'));

    // Check if the cookie is set at all
    if (!document.cookie.split(';').some(item => item.trim().startsWith('COOKIE_CONSENT='))) {
        document.getElementById('cookie-banner').style.display = 'block';
    }

    document.getElementById("allow-cookies").addEventListener("click", function() {
        window.localStorage.setItem('COOKIE_CONSENT', 'true');
        document.cookie = 'COOKIE_CONSENT=true;path=/; Expires=Wed, 05 Aug 2100 23:00:00 UTC; domain=' + window.location.hostname;
        location.reload();
    });

    document.getElementById("decline-cookies").addEventListener("click", function() {
        document.cookie = 'COOKIE_CONSENT=false;path=/;domain=' + window.location.hostname;
        document.getElementById('cookie-banner').style.display = 'none';
    });
}
