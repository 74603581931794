document.addEventListener('DOMContentLoaded', () => {
    filtersMobileDisplay();
    window.addEventListener('resize', filtersMobileDisplay);
});

function filtersMobileDisplay() {
    const filtersMobileButton = document.getElementById('filters-mobile');
    const filtersMobileElements = document.querySelectorAll('#positions-filters, #organisations-filters, #institutes-filters');
    const body = document.querySelector('body');
    const close = document.getElementById('close-filters');

    if (!filtersMobileButton || filtersMobileElements.length === 0) {
        return;
    }

    filtersMobileButton.removeEventListener('click', openFilters);
    if (close) {
        close.removeEventListener('click', closeFilters);
    }

    if (window.innerWidth <= 1023) {
        filtersMobileButton.addEventListener('click', openFilters);
        if (close) {
            close.addEventListener('click', closeFilters);
        }
    }

    function openFilters() {
        body.classList.add('overflow-hidden');
        filtersMobileButton.classList.add('open');
        filtersMobileElements.forEach(filter => {
            filter.classList.add('open');
        });
    }

    function closeFilters() {
        body.classList.remove('overflow-hidden');
        filtersMobileButton.classList.remove('open');
        filtersMobileElements.forEach(filter => {
            filter.classList.remove('open');
        });
    }
}
