window.openApplicationModal = (vacancyId) => {
    const modal = document.querySelector('.js-eportfolio_modal');
    const eportfolioLink = document.querySelector('.js-eportfolio-link');
    const easyApplyForm = document.querySelector('.js-form_easy_apply');
    const vacancyIdInput = easyApplyForm.querySelector('[name=vacancy_id]');

    modal.classList.remove('hidden')
    vacancyIdInput.value = vacancyId;

    eportfolioLink.href = "https://zorgselect.eportfolioapp.nl/vacature/" + vacancyId;
}

window.openDirectApply = () => {
    const modal = document.querySelector('.js-eportfolio_modal');
    modal.classList.add('hidden');

    const directApplyModal = document.querySelector('.js-eportfolio_applicationForm');
    directApplyModal.classList.remove('hidden');
}

window.closeModal = () => {
    const directApplyModal = document.querySelector('.js-eportfolio_applicationForm');
    const initialApplyModal = document.querySelector('.js-eportfolio_modal');

    directApplyModal.classList.add('hidden');
    initialApplyModal.classList.add('hidden');
}

let isSendingApplication = false;
window.sendApplication = () => {
    if (isSendingApplication)
        return;

    isSendingApplication = true;
    const submitButton = document.querySelector('.js-form_easy_apply_submit');
    submitButton.disabled = true;

    const form = document.querySelector('.js-form_easy_apply');
    const formData = new FormData(form);

    if (formData.get("referral_name") === "other") {
        formData.set("referral_name", formData.get("other_referral"));
    }

    fetch("https://eportfolio-api.zorgselect.nl/api/easy-apply", {
        method: "POST",
        headers: { "Accept": "application/json" },
        body: formData,
    })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => {
                    throw err;
                });
            }
            return response.json();
        })
        .then(response => {
            if (response.redirect) {
                window.location.replace(response.redirect);
                return;
            }
            clearForm();
            closeModal();
            alert(response.message);
            injectConversionCode(response.conversion_code);

            if (typeof _trackInteraction === 'function') {
                _trackInteraction('custom', { data: 'apply.success' });
            }
        })
        .catch(error => {
            document.querySelector(".js-eportfolio_login_modal #error_list").innerHTML = "";
            document.querySelectorAll(".js-eportfolio_login_modal .has-error").forEach(el => el.classList.remove("has-error"));

            if (error.status === 413) {
                document.getElementById("error_list").classList.remove("hidden");
                let li = document.createElement("li");
                li.textContent = "Een of beide bestanden zijn te groot, zorg ervoor dat ze kleiner zijn dan 100 MB.";
                document.getElementById("error_list").appendChild(li);
            } else if (error.errors) {
                error.errors.forEach(err => {
                    const field = err.field.replace('.', '\\.')
                    let fieldElement = document.querySelector(`.js-form_easy_apply #${field}`);
                    if (fieldElement) {
                        fieldElement.parentElement.classList.add("has-error");
                        fieldElement.parentElement.parentElement.classList.add("has-error");
                    }
                    if (field === "referral_name") {
                        let otherReferral = document.querySelector(".js-form_easy_apply #other_referral");
                        if (otherReferral) {
                            otherReferral.parentElement.classList.add("has-error");
                        }
                    }
                    document.getElementById("error_list").classList.remove("hidden");
                    let li = document.createElement("li");
                    li.textContent = err.message;
                    document.getElementById("error_list").appendChild(li);
                });
            }
        })
        .finally(() => {
            isSendingApplication = false;
            document.querySelector(".js-form_easy_apply_submit").disabled = false;
            document.querySelector(".eportfolio-login--svg-loader").style.display = "none";
            document.querySelector(".js-eportfolio_login_modal").scrollTo({ top: 0, behavior: "smooth" });
        });
}

function clearForm() {
    document.querySelector(".js-eportfolio_login_modal #error_list").innerHTML = "";
    document.querySelector(".js-eportfolio_login_modal #error_list").classList.add("hidden");
    document.querySelectorAll(".js-eportfolio_login_modal .has-error").forEach(el => el.classList.remove("has-error"));
    document.querySelectorAll(".js-eportfolio_login_modal input[type=text]").forEach(input => input.value = "");
    document.querySelectorAll(".js-eportfolio_login_modal select").forEach(select => select.value = "");
    document.querySelectorAll(".js-eportfolio_login_modal textarea").forEach(textarea => textarea.value = "");
}

function injectConversionCode(strCode) {
    if (strCode) {
        document.body.insertAdjacentHTML("beforeend", strCode);
    }
}
